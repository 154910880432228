.App {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px;
  
}

.App a {
  display: inline-block; /* Makes the anchor tag as large as its content */
  max-width: 400px;
}

.App a img {
  display: block;       /* Removes any default spacing around the image */
  max-width: 100%;
  height: auto;
  margin: 0 auto;

}

@media (max-width: 600px) {
  .App {
    flex-direction: column;
  }

  .App a img {
    max-width: 100%;
  }
}
